<template>
  <b-card>
    <b-form v-if="aciklama">
      <b-row>
        <b-col md="6">
          <label class="mb-1">{{ $t('Açıklama') }}</label>
          <editor
            v-model="aciklama.sBannerAciklama"
            api-key="'qagffr3pkuv17a8on1afax661irst1hbr4e6tbv888sz91jc'"
            :init="init"
          />
        </b-col>
        <b-col md="6">
          <label class="mb-0">{{ $t('Resim') }}</label>
          <div class="bg-light-primary rounded-top text-center mt-1 banner-img-box">
            <b-img :src="aciklama.sBannerResimUrl" class="responsive" />
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-primary btn-image-update"
              class="mt-0 mt-md-2"
              @click="openUploadFile()"
            >
              <feather-icon icon="PaperclipIcon" class="mr-25" />
              <span>{{ !aciklama.sBannerResimUrl ? $t('Ekle') : $t('Değiştir') }}</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import Ripple from 'vue-ripple-directive';
import Editor from '@tinymce/tinymce-vue';

export default {
  components: { Editor },
  directives: {
    Ripple,
  },
  props: {
    aciklama: {
      type: Object,
      default: () => { },
    },

    openUpload: {
      type: Function,
      default: () => { },
    },
  },
  data() {
    return {
      init: {
        plugins: ['print preview paste importcss searchreplace autolink autosave save directionality code fullscreen image link media template table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap uploadimage'],
        toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat colorpicker | pagebreak | charmap | fullscreen  preview  print | insertfile image media pageembed link anchor | a11ycheck ltr rtl | showcomments addcomment | uploadimage',
        language: 'tr_TR',
        min_height: 350,
        valid_elements: '*[*]',
        extended_valid_elements: 'style,link[href|rel]',
        custom_elements: 'style,link,~link',
        force_br_newlines: true,
        forced_root_block: false,
      },
    }
  },
  methods: {
    openUploadFile() {
      this.openUpload(this.aciklama.field, base64 => {
        this.aciklama.sBannerResimUrl = base64;
      })
    },
  },
}
</script>

<style scoped>
.banner-img-box {
  height: 90%;
}

.banner-img-box img {
  height: 350px;
  width: auto;
}

.btn-image-update {
  position: absolute !important;
  bottom: 0px;
  right: 15px;
}

</style>
