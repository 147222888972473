<template>
  <div>
    <banner-genel v-if="bannerGenelData" :banner-genel-data="bannerGenelData" />
    <b-card>
      <b-tabs pills>
        <!-- Tab: Açıklama 1 -->
        <b-tab active>
          <template #title>
            <feather-icon
              icon="MenuIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">{{ $t('Açıklama') }} 1</span>
          </template>
          <banner-aciklama v-if="bannerGenelData" :openUpload="openUpload" :aciklama="aciklama1" />
        </b-tab>

        <!-- Tab: Açıklama 2 -->
        <b-tab>
          <template #title>
            <feather-icon
              icon="MenuIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">{{ $t('Açıklama') }} 2</span>
          </template>
          <banner-aciklama v-if="bannerGenelData" :openUpload="openUpload" :aciklama="aciklama2" />
        </b-tab>
      </b-tabs>
    </b-card>
    
    <b-card no-body class="pl-1 pb-1">
      <save-button
        :showSaveAndClose="false"
        :onClickSave="save"
        
      />
    </b-card>
  </div>
</template>

<script>
import { inputImageRenderer } from '@core/comp-functions/forms/form-utils';
import AlertService from '@/common/alert.service';
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue';
import SaveButton from '@/components/SaveButton.vue';
import BannerGenel from './banner-detay/BannerGenel.vue';
import BannerAciklama from './banner-detay/BannerAciklama.vue';

export default {
  components: { BannerGenel, BannerAciklama, SaveButton },
  data() {
    return {
      bannerGenelData: {
        sBaslik: '',
        sDilKodu: 'tr',
        ldSira: 1,
        isActive: true,
      },
      form: new FormData(),
      aciklama1: { sBannerAciklama: '', sBannerResimUrl: '', field: 1 },
      aciklama2: { sBannerAciklama: '', sBannerResimUrl: '', field: 2 },
    }
  },

  created() {
    const routeId = parseInt(this.$route.params.id, 10);
    if (routeId !== -1) {
      this.$store.dispatch('bannerYonetim/fetchBanner', routeId)
        .then(response => {
          if (response.statusCode === 200) {
            const { id, sBaslik, sDilKodu, ldSira, isActive } = response.result;
            const { sBannerAciklama1, sBannerResimUrl1 } = response.result;
            const { sBannerAciklama2, sBannerResimUrl2 } = response.result;
            this.bannerGenelData = { id, sBaslik, sDilKodu, ldSira, isActive };
            this.aciklama1 = { sBannerAciklama: sBannerAciklama1, sBannerResimUrl: sBannerResimUrl1, field: 1 }
            this.aciklama2 = { sBannerAciklama: sBannerAciklama2, sBannerResimUrl: sBannerResimUrl2, field: 2 };
          } else {
            AlertService.error(this, response.message)
          }
        });
    }
  },

  methods: {
    openUpload(field, callback) {
      const fileInput = document.createElement('input');
      fileInput.type = 'file';
      fileInput.accept = 'image/png, image/jpeg'
      fileInput.addEventListener('change', event => {
        this.form.set(`banner${field}`, event.target.files[0]);
        inputImageRenderer(event, base64 => {
          callback(base64)
        })
      })
      fileInput.click();
    },

    save() {
      if (this.bannerGenelData.sDilKodu === '' || this.bannerGenelData.ldSira === '' || this.bannerGenelData.sBaslik === '') {
        AlertService.warning(this, 'Genel alanını doldurun');
        return;
      }
      this.form.set('id', this.bannerGenelData.id || 0);
      this.form.set('sBaslik', this.bannerGenelData.sBaslik);
      this.form.set('sDilKodu', this.bannerGenelData.sDilKodu);
      this.form.set('ldSira', this.bannerGenelData.ldSira);
      this.form.set('isActive', this.bannerGenelData.isActive);
      this.form.set('sBannerAciklama1', this.aciklama1.sBannerAciklama || '');
      this.form.set('sBannerAciklama2', this.aciklama2.sBannerAciklama || '');
      this.form.set('folderName', 'banner-resim');

      this.$store.dispatch('bannerYonetim/saveBanner', this.form, this)
        .then(response => {
          if (response.statusCode === 200) {
            this.$toast({
              component: ToastificationSuccess,
              position: 'top-right',
              props: { text: response.message },
            })
            this.$router.go(-1)
          } else {
            AlertService.error(this, response.message)
          }
        });
    },
  },

}
</script>

<style>
:focus {
  outline: 0 !important;
}
</style>
