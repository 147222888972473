export const inputImageRenderer = (event, callback) => {
    const file = event.target.files[0]
    const reader = new FileReader()
    reader.addEventListener(
      'load',
      () => {
        callback(reader.result)
      },
      false,
    )

    if (file) {
      reader.readAsDataURL(file)
    }
  }

export const useInputImageFiles = (inputEl, callback) => {
  const inputImageFiles = () => {
    if (inputEl.value.files) {
      callback(inputEl.value.files)
    }
  }
  return {
    inputImageFiles,
  }
}

export const _ = null
