<template>
  <b-card>
    <!-- Header: Personal Info -->
    <div class="d-flex">
      <feather-icon icon="SquareIcon" size="19" class="text-primary" />
      <h4 class="mb-0 ml-50 text-primary"> {{ $t('Genel Bilgiler') }}</h4>
    </div>
    <b-form class="mt-1">
      <b-row>
        <b-col>
          <b-form-group :label="$t('Dil')">
            <v-select
              v-model="bannerGenelData.sDilKodu"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="dilOptions"
              :reduce="(val) => val.value"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group :label="$t('Aktif Durum')">
            <b-form-checkbox
              v-model="bannerGenelData.isActive"
              :checked="bannerGenelData.isActive"
              class="custom-control-success"
              name="check-button"
            >
              {{ $t('Aktif/Pasif') }}
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group :label="$t('Başlık')">
            <b-form-input
              v-model="bannerGenelData.sBaslik"
              type="text"
              :placeholder="$t('Başlık Giriniz')"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Sıra">
            <b-form-input
              v-model="bannerGenelData.ldSira"
              min="1"
              type="number"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import StaticOptions from '@/common/options/StaticOptions';

export default {
  props: {
    bannerGenelData: {
      type: Object,
      default: () => { },
    },
  },

  data() {
    return {
      dilOptions: StaticOptions.dilOptions,
    }
  },
}
</script>

<style>
</style>
